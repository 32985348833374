.fully-booked-day {
    background-color: red; 
    color: white;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  